<template>
  <div class="layoutCard">
    <KValidationObserver ref="observer">
      <div class="cardHead">
        {{ $g("notification-preferences") }}
      </div>
      <div class="cardBody">
        <k-form-group
          v-if="
            getConfigVal(
              curPageConfigAndDefaultPageConfig,
              'languageSelect',
              'vIf'
            )
          "
          id="languageSelect"
          label-suffix="language"
          label="language"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          :label-class="'require-mark'"
          label-for="noneBind"
        >
          <KValidationProvider
            rules="required"
            v-slot="{ errors }"
            slim
            name="language"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-multiselect
              :multiple="false"
              v-model="applicationFromData.languageId"
              :options="systemLanguageList"
              :plaintext="!edit"
            />
          </KValidationProvider>
        </k-form-group>

        <k-form-group
          label-suffix="preferredContactMethod"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          :label-class="'require-mark'"
        >
          <KValidationProvider
            rules="required"
            v-slot="{ errors }"
            slim
            name="preferredContactMethod"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-select
              v-model="applicationFromData.preferredContactMethod"
              :options="enumCommunicationSendTypeList"
              :plaintext="!edit || nonEditableFields['PreferredContactMethod']"
              :aria-label="$g('preferredContactMethod')"
            />
          </KValidationProvider>
        </k-form-group>

        <k-form-group
          label-suffix="email"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapHide767"
        >
          <b-form-radio-group
            v-model="applicationFromData.useEmail"
            class="col-form-label"
            :plaintext="!edit"
            :disabled="!edit"
          >
            <b-form-radio :value="true">
              {{ $g("on") }}
            </b-form-radio>
            <b-form-radio :value="false">
              {{ $g("off") }}
            </b-form-radio>
          </b-form-radio-group>
        </k-form-group>
        <div class="wapShow767 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold mt-1">
              {{ $g("email") }}{{ $g(":") }}
            </span>
            <b-form-checkbox
              v-model="applicationFromData.useEmail"
              :plaintext="!edit"
              :disabled="!edit"
              switch
              size="lg"
            />
          </div>
        </div>
        <k-form-group
          label-suffix="emailAddress"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          :label-class="applicationFromData.useEmail ? 'require-mark' : ''"
        >
          <KValidationProvider
            :rules="{
              max: 200,
              email: true,
              required: applicationFromData.useEmail,
            }"
            name="email"
            v-slot="{ errors }"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-input
              id="emailAddress"
              v-model="applicationFromData.email"
              :plaintext="!edit"
              :aria-label="$g('emailAddress')"
            />
          </KValidationProvider>
        </k-form-group>

        <k-form-group
          v-if="
            getConfigVal(
              curPageConfigAndDefaultPageConfig,
              'messagesRadio',
              'vIf'
            )
          "
          label-suffix="messages"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapHide767"
        >
          <b-form-radio-group
            v-model="applicationFromData.useSms"
            class="col-form-label"
            :plaintext="!edit"
            :disabled="!edit"
          >
            <b-form-radio :value="true">
              {{ $g("on") }}
            </b-form-radio>
            <b-form-radio :value="false">
              {{ $g("off") }}
            </b-form-radio>
          </b-form-radio-group>
        </k-form-group>
        <div class="wapShow767 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold mt-1">
              {{ $g("messages") }}{{ $g(":") }}
            </span>
            <b-form-checkbox
              v-model="applicationFromData.useSms"
              :plaintext="!edit"
              :disabled="!edit"
              switch
              size="lg"
            />
          </div>
        </div>
        <k-form-group
          label-suffix="smsPhoneNumber"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          :label-class="applicationFromData.useSms ? 'require-mark' : ''"
        >
          <KValidationProvider
            :rules="{
              phone: true,
              required: applicationFromData.useSms,
            }"
            slim
            name="phoneNumber"
            v-slot="{ errors }"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-phone-input
              id="phoneNumber"
              v-model="applicationFromData.sms"
              :plaintext="!edit"
              :aria-label="$g('phoneNumber')"
            />
          </KValidationProvider>
        </k-form-group>

        <k-form-group
          v-if="
            getConfigVal(
              curPageConfigAndDefaultPageConfig,
              'phoneCallRadio',
              'vIf'
            )
          "
          label-suffix="phoneCall"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapHide767"
        >
          <b-form-radio-group
            v-model="applicationFromData.usePhone"
            class="col-form-label"
            :plaintext="!edit"
            :disabled="!edit"
          >
            <b-form-radio :value="true">
              {{ $g("on") }}
            </b-form-radio>
            <b-form-radio :value="false">
              {{ $g("off") }}
            </b-form-radio>
          </b-form-radio-group>
        </k-form-group>
        <div class="wapShow767 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold mt-1">
              {{ $g("phoneCall") }}{{ $g(":") }}
            </span>
            <b-form-checkbox
              v-model="applicationFromData.usePhone"
              :plaintext="!edit"
              :disabled="!edit"
              switch
              size="lg"
            />
          </div>
        </div>
        <k-form-group
          v-if="
            getConfigVal(
              curPageConfigAndDefaultPageConfig,
              'phoneCallPhoneNumberInput',
              'vIf'
            )
          "
          label-suffix="phoneCallPhoneNumber"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          :label-class="applicationFromData.usePhone ? 'require-mark' : ''"
        >
          <KValidationProvider
            :rules="{
              phone: true,
              required: applicationFromData.usePhone,
            }"
            slim
            name="phoneNumber2"
            v-slot="{ errors }"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-phone-input
              id="phoneNumber2"
              v-model="applicationFromData.phone"
              :plaintext="!edit"
              :aria-label="$g('phoneNumber')"
            />
          </KValidationProvider>
        </k-form-group>
        <k-form-group
          v-if="
            getConfigVal(
              curPageConfigAndDefaultPageConfig,
              'additionalPhoneNumberInput',
              'vIf'
            ) &&
            getConfigVal(
              curPageConfigAndDefaultPageConfig,
              'additionalPhoneNumberUnderWorkPhone',
              'vIf'
            )
          "
          id="additionalPhoneNumberInput"
          label-suffix="additionalPhoneNumber"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
        >
          <KValidationProvider
            :rules="{
              phone: true,
            }"
            slim
            name="additionalPhoneNumber"
            v-slot="{ errors }"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-phone-input
              id="additionalPhoneNumber"
              v-model="applicationFromData.additionalPhoneNumber"
              :aria-label="$g('additionalPhoneNumber')"
              :plaintext="!edit"
            />
          </KValidationProvider>
        </k-form-group>
        <k-form-group
          label-suffix="doNotDisturbHours"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapHide767"
        >
          <b-form-radio-group
            v-model="applicationFromData.notDisturb"
            class="col-form-label"
            :plaintext="!edit"
            :disabled="!edit"
          >
            <b-form-radio :value="true">
              {{ $g("on") }}
            </b-form-radio>
            <b-form-radio :value="false">
              {{ $g("off") }}
            </b-form-radio>
          </b-form-radio-group>
        </k-form-group>
        <div class="wapShow767 mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold mt-1">
              {{ $g("doNotDisturbHours") }}{{ $g(":") }}
            </span>
            <b-form-checkbox
              v-model="applicationFromData.notDisturb"
              :plaintext="!edit"
              :disabled="!edit"
              switch
              size="lg"
            />
          </div>
        </div>
        <k-form-group
          label-suffix="hours"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          :label-class="applicationFromData.notDisturb ? 'require-mark' : ''"
          label-for="noneBind"
          v-if="applicationFromData.notDisturb"
        >
          <KValidationProvider
            :rules="{
              required:
                applicationFromData.notDisturb ||
                applicationFromData.notDisturbTo > 0,
              validateTimeFormat: true,
            }"
            slim
            v-slot="{ errors }"
            class="inline-time"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-timepicker
              v-model="applicationFromData.notDisturbFrom"
              class="datepicker-time"
              :plaintext="!edit"
              :class="{ minWidth: !edit }"
            />
          </KValidationProvider>
          <span class="timePart">-</span>
          <KValidationProvider
            :rules="{
              required:
                applicationFromData.notDisturb ||
                applicationFromData.notDisturbFrom > 0,
              validateTimeFormat: true,
            }"
            slim
            v-slot="{ errors }"
            class="inline-time"
          >
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-form-timepicker
              v-model="applicationFromData.notDisturbTo"
              class="datepicker-time"
              :plaintext="!edit"
              :class="{ minWidth: !edit }"
            />
          </KValidationProvider>
        </k-form-group>
      </div>
    </KValidationObserver>
  </div>
</template>

<script>
import { getPersonLanguageList } from "@/api/home";
import {
  globalEnv,
  getCurPageConfigbyRoutePath,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";

export default {
  props: {
    edit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    application: {
      type: Object,
      default: function () {
        return {};
      },
    },
    nonEditableFields: {
      type: Object,
      default() {
        return { PreferredContactMethod: false };
      },
    },
  },
  model: { prop: "application", event: "input" },
  components: {},
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    let tempEnumCommunicationSendTypeList = getConfigVal(
      curPageConfigAndDefaultPageConfig,
      "enumCommunicationSendTypeList",
      "options"
    );
    tempEnumCommunicationSendTypeList.forEach((item) => {
      if (item.text) {
        item.text = this.$g(item.text);
      }
    });
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      applicationFromData: {
        languageId: "",
        preferredContactMethod: "",
        useEmail: "",
        email: "",
        useSms: "",
        sms: "",
        usePhone: "",
        phone: "",
        notDisturb: "",
        notDisturbFrom: "",
        notDisturbTo: "",
        additionalPhoneNumber: "",
      },
      systemLanguageList: [],
      enumCommunicationSendTypeList: tempEnumCommunicationSendTypeList,
      // curSystemLanguage: "",
      currEnumCommunicationSendType: "",
      electionId: this.electionId,
    };
  },
  computed: {
    languageSelectVIF() {
      return this.getConfigVal(
        this.curPageConfigAndDefaultPageConfig,
        "languageSelect",
        "vIf"
      );
    },
  },
  mounted() {
    this.bindData();
    this.bindSystemType();
  },
  methods: {
    bindSystemType() {
      this.bindLanguageList();
    },

    bindLanguageList() {
      getPersonLanguageList().then((resp) => {
        if (resp.data && resp.data.length > 0) {
          this.systemLanguageList.push({ text: "", value: "" });
          resp.data.forEach((element) => {
            this.systemLanguageList.push(element);
          });
          const l = this.systemLanguageList.find(
            (it) => it.value == this.application.languageId
          );
          if (l != null) {
            this.applicationFromData.languageId = this.application.languageId;
          }
          if (
            this.languageSelectVIF &&
            (!this.applicationFromData.languageId ||
              this.applicationFromData.languageId.length == 0)
          ) {
            this.systemLanguageList.forEach((item) => {
              if (item.isDefault) {
                this.applicationFromData.languageId = item.value;
              }
            });
          }
        }
      });
    },

    onTimeChange() {
      let me = this;
      setTimeout(function () {
        me.$refs.observer.validate();
      }, 1);
    },
    bindData() {
      if (this.application.preferredContactMethod != 0) {
        this.applicationFromData.preferredContactMethod =
          this.application.preferredContactMethod;
      }
      this.$set(
        this.applicationFromData,
        "useEmail",
        this.application.useEmail
      );
      this.$set(this.applicationFromData, "email", this.application.email);
      this.$set(
        this.applicationFromData,
        "useSms",
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "messagesRadio",
          "vIf"
        )
          ? this.application.useSms
          : false
      );
      this.$set(this.applicationFromData, "sms", this.application.sms);

      this.$set(
        this.applicationFromData,
        "usePhone",
        this.application.usePhone
      );
      this.$set(this.applicationFromData, "phone", this.application.phone);

      this.$set(
        this.applicationFromData,
        "notDisturb",
        this.application.notDisturb
      );
      this.$set(
        this.applicationFromData,
        "notDisturbFrom",
        this.application.notDisturbFrom == 0 ||
          this.application.notDisturbFrom == -2208988800000
          ? null
          : this.application.notDisturbFrom
      );
      this.$set(
        this.applicationFromData,
        "notDisturbTo",
        this.application.notDisturbTo == 0 ||
          this.application.notDisturbTo == -2208988800000
          ? null
          : this.application.notDisturbTo
      );
      this.$set(
        this.applicationFromData,
        "additionalPhoneNumber",
        this.application.additionalPhoneNumber
      );
    },
  },
  watch: {
    applicationFromData: {
      handler: function (np) {
        if (!np.notDisturb) {
          np.notDisturbFrom = "";
          np.notDisturbTo = "";
        }
        const data = {};
        data.languageId =
          np.languageId && np.languageId.length > 0
            ? np.languageId
            : this.application.languageId;
        data.preferredContactMethod = np.preferredContactMethod;
        data.useEmail = np.useEmail;
        data.email = np.email;
        data.useSms = np.useSms;
        data.sms = np.sms;
        data.usePhone = np.usePhone;
        data.phone = np.phone;
        data.notDisturb = np.notDisturb;
        data.notDisturbFrom = np.notDisturbFrom;
        data.notDisturbTo = np.notDisturbTo;
        data.additionalPhoneNumber = np.additionalPhoneNumber;
        this.$emit("input", Object.assign(this.application, data));
      },
      deep: true,
    },
  },
};
</script>
